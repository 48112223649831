import { observer } from 'mobx-react';
import withUserInfo from '@/layouts/userInfo';
import { parseCookies } from 'nookies';
import Introduction from "@/pages/introduction/index.page.tsx";

function Square(props: any) {
  return (
    <Introduction></Introduction>
  )
}

Square.getInitialProps = async ({ query, ...content }: { query: any, content: any }) => {
  const cookie = parseCookies(content);
  const isLogin = cookie['botvip_access_token'];
  if (isLogin) {
    content.res?.writeHead(302, {
      Location: '/square/recommend',
    });
    content.res?.end();
    return {};
  } else {
    return {}
  }
};

export default withUserInfo(observer(Square));
